

























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Menu: () => import('@/components/business/header/InsElMenu.vue')
  }
})
export default class InsFooterLayout extends Vue {
  @Prop() footerContact!: object;
  currentYear: number = 0;
  tabIndex: number = 1;
  showSub: boolean = false;
  show: boolean = true;

  toggleTab (index) {
    this.tabIndex = index;
  }

  created () {
    var date = new Date();
    this.currentYear = date.getFullYear();
  }
}
